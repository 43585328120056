<template>
  <input
    class="input-text"
    :type="type"
    :name="name"
    :value="value"
    @input="$emit('input', $event.target.value)"
  >
</template>

<script>
export default {
  name: 'InputText',
  props: {
    value: [String, Number],
    name: String,
    type: {
      type: String,
      default: 'text',
    },
  },
};
</script>

<style lang="scss" scoped>
.input-text {
  @include styled-input;
  background-color: $gray-900;
  color: $gray-500;
}
</style>
