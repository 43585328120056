<template>
  <div class="about container">
    <div class="hero">
      <img class="hero-visual" src="@/assets/img/hero-computer.jpg" alt="">
      <div class="hero-content">
        <h1>Analyzujte ceny nehnuteľností</h1>
        <p>Preskúmajte podrobné štatistiky o nehnuteľnostiach v lokalite, ktorá vás zaujíma.</p>
        <BaseButton @click="$router.push({ name: 'Explorer' })">Zobraziť ceny na mape</BaseButton>
      </div>
    </div>
    <div class="features row">
      <div
        v-for="feature in features"
        :key="feature.title"
        class="col feature"
      >
        <img class="feature-icon" :src="feature.icon" :alt="feature.title">
        <h2 class="feature-title" v-html="feature.title.replace('\n', '<br>')"/>
        <p class="feature-text">{{ feature.text }}</p>
      </div>
    </div>
    <NewsletterSignup/>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import NewsletterSignup from '@/components/NewsletterSignup.vue';

export default {
  components: {
    BaseButton,
    NewsletterSignup,
  },
  /* eslint-disable global-require */
  data: () => ({
    features: [
      {
        title: 'Vyberte\n lokalitu',
        icon: require('@/assets/img/icons/marker.svg'),
        text: 'Pomocou mnohouholníka alebo okruhu vyznačte presnú lokalitu, ktorá vás zaujíma a okamžite získajte informácie o priemernej cene a ďalších vlastnostiach nájdených nehnuteľností.',
      },
      {
        title: 'Pokročilé\n filtrovanie',
        icon: require('@/assets/img/icons/house.svg'),
        text: 'Vyfiltrujte nehnuteľnosti na základe typu (byty, domy, pozemky), stavu, počtu izieb, veľkosti či ceny, aby výstup presne zodpovedal vašim požadovaným kritériám.',
      },
      {
        title: 'Zmapované\n celé Slovensko',
        icon: require('@/assets/img/icons/slovakia.svg'),
        text: 'Naša databáza obsahuje viac ako 40-tisíc nehnuteľností z celého Slovenska a je starostlivo očistená od všetkých neúplných, chybných a duplicitných záznamov, ktoré by mohli skresliť výstup.',
      },
      {
        title: 'Nájdite jedinečné ponuky',
        icon: require('@/assets/img/icons/deal.svg'),
        text: 'Poskytnuté údaje vám pomôžu vytypovať výhodné ponuky a získať komplexný trhový prehľad o danej oblasti, ako aj podrobné údaje o jednotlivých nehnuteľnostiach.',
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.hero {
  margin-top: 2rem;
  margin-bottom: 5rem;
  .hero-visual {
    max-width: 100%;
    height: auto;
    border-radius: 1.25rem;
    border: 1px solid rgba($primary, 0.12);
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    .hero-visual {
      flex: 0 0 60%;
      max-width: 60%;
      margin-bottom: 0;
    }
    .hero-content {
      padding: 0 3rem;
      font-size: 1.25rem;
      h1 {
        max-width: 25rem;
      }
      p {
        margin-bottom: 2rem;
      }
    }
  }
}

.features {
  margin-bottom: 50px;
}

.feature {
  text-align: center;
  .feature-icon {
    display: block;
    max-width: 80px;
    margin: 0 auto 1.25rem;
  }
  .feature-title {
    margin-bottom: 0.75rem;
  }
  .feature-text {
    color: $gray-500;
  }
}

</style>
