<template>
  <div v-if="mode === 'add'" class="newsletter-signup">
    <header class="header">
      <h2 class="title">Prihláste sa na odber noviniek</h2>
      <p>Pripravujeme veľa ďalších funkcií, ktoré používateľom ponúknu jedinečný pohľad na slovenský realitný trh.</p>
    </header>
    <form @submit.prevent="subscribe()" class="form">
      <InputText v-model="email" type="email"/>
      <BaseButton type="submit" class="button">Prihlásiť</BaseButton>
    </form>
    <div class="footer"><a href="#" @click.prevent="mode = 'remove'">Odhlásenie</a></div>
  </div>
  <div v-else-if="mode === 'remove'" class="newsletter-signup">
    <header class="header">
      <h2 class="title">Odhlásenie z odberu</h2>
    </header>
    <form @submit.prevent="unsubscribe()" class="form">
      <InputText v-model="email" type="email"/>
      <BaseButton type="submit" class="button">Odhlásiť</BaseButton>
    </form>
    <div class="footer"><a href="#" @click.prevent="mode = 'add'">Prihlásenie</a></div>
  </div>

</template>

<script>
import api from '@/api';

import BaseButton from '@/components/BaseButton.vue';
import InputText from '@/components/InputText.vue';

export default {
  name: 'NewsletterSignup',
  components: {
    BaseButton,
    InputText,
  },
  data: () => ({
    mode: 'add',
    email: '',
  }),
  methods: {
    async subscribe() {
      const response = await api.post('/v1/newsletter/subscribe', { email: this.email });
      if (response.status === 200) {
        this.email = '';
        this.$toasted.success('Ďakujeme, adresa je uložená.');
      }
    },
    async unsubscribe() {
      const response = await api.post('/v1/newsletter/unsubscribe', { email: this.email });
      if (response.status === 200) {
        this.email = '';
        this.$toasted.success('Operácia spracovaná.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.newsletter-signup {
  border-radius: 10px;
  background-color: $gray-800;
  color: $gray-500;
  max-width: 690px;
  margin: 0 auto 4rem;
  padding: 2rem 4rem;
  text-align: center;
  .title {
    margin-top: 0;
    color: $gray-300;
  }
  .header {
    margin-bottom: 1rem;
  }
  .form {
    display: flex;
    margin-bottom: 1rem;
  }
  .button {
    margin-left: 0.5rem;
  }
  .footer {
    font-size: 0.75rem;
  }
  .footer a {
    color: $gray-500;
  }
}
</style>
